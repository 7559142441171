.filters-container {
    width: 100%;
    box-sizing: border-box;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* grid-template-columns: minmax(max-content, 80px) 1fr minmax(max-content, 80px) 1fr; */
    display: grid;
    column-gap: 24px;
    row-gap: 4px;
}

/* .filter-with-label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    flex: 1 1 49%;
    min-width: calc(50% - 32px);
    max-width: 50%;
} */

/* .filter-with-label.full-width {
    max-width: unset;
}

.filter-with-label:nth-child(even) {
    margin-left: 16px;
} */


 .label {
    text-align: left;
    /* margin-right: 32px; */
    white-space: nowrap;
    /* width: 120px; */
    height: 100%; 
    padding-top: 12px;
    display: flex; 
    align-items: center;

}

.filter-with-label .filter {
    width: 100%;
    flex: 1;
}

/* .collapsible-area {
    max-height: 1000px;
    transition: all 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.collapsible-area.collapsed {
    max-height: 0;
    overflow: hidden;
} */